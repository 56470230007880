import React from 'react';

import HoverMenu from '../elements/hoverMenu';



class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (<div className="mb-3">
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", gap: "10px", padding: "0 10px" }}>
                {this.props.config.startMenu.map((item, i) => {
                    return <div className="indexMenu" style={{ flex: 10 }} key={i}>
                        <HoverMenu {...item} />
                    </div>
                })}
            </div>
        </div>);
    }
}

export default Menu;