import React from 'react';
import Menu from '../components/menu';

class Therapiezentrum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        window.scrollTo(0, 0);
        return (<>

            <div style={{ marginTop: "100px" }} className="animate__animated animate__fadeIn">

                <div className="">
                    {this.props.mobileVersion ? "" : <Menu {...this.props} />}
                </div>

                <div className='container'>
                    <div className='bg-white rounded shadow-sm p-4 m-5'>
                        <h3 className="p-2 text-info bg-white text-center text-success m-0">Therapiezentrum am Waldkater</h3>


                        <p className='text-secondary mt-3'>
                            Zur interdisziplinären Behandlung unserer Bewohner, Klienten und hier betreuten Personen
                            (von jung bis alt, in unterschiedlichen Fachrichtungen) haben wir das Therapiezentrum am Waldkater gegründet.
                            Bei uns steht der jeweilige Mensch mit seinen individuellen Bedürfnissen im Vordergrund.
                        </p>

                        <p className='text-secondary'> <b>Weitere Informationen folgen in Kürze...</b></p>
                    </div>
                </div>

            </div>
        </>);
    }
}

export default Therapiezentrum;