import React from 'react';

// Bilder
import Logo from '../css/image/logo.jpg';
import qrCode from "../css/image/qrcode.png";

const Video_tv = () => {
    return <>
        <div style={{ backgroundColor: "white", width: "100%", height: "100vh", fontSize: "5.5vw", }}>

            <div className="d-flex align-items-center justify-content-center pt-5">
                <img className="" src={Logo} alt="logo" width="90%" height="auto" />
            </div>


            <video className="start-video" autoPlay muted loop playsInline style={{ width: "100%", height: "58vh", objectFit: "contain" }}>
                <source src="/video1.mp4" type="video/mp4" />
                Dein Browser unterstützt das Video-Tag nicht.
            </video>


            <div className="d-flex ff-2 text-gray" style={{ fontSize: "4vw", marginTop: "3vh", padding: "0 3vw" }}>
                <img src={qrCode} style={{ width: "30vw" }} height="auto" alt='qrCode' className='border' />
                <div className="text-dark d-flex ff-1" style={{ flex: 1, }}>
                    <span className="m-auto text-center" style={{ lineHeight: "150px" }}>
                        <span className="ff-3">Besuchen Sie uns auf</span> <br />
                        <u className="text-info ff-2">www.lebenshilfe-rinteln.de</u>
                    </span>
                </div>
            </div>
        </div>
    </>
}

export default Video_tv;